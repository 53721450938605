.legalSection {
    padding-bottom: 100px;
    background-color: #111;

    max-width: 1200px;
    margin: 0 auto 4rem;
    padding: 20px;
}

.title {
    margin-top: 25vh;
    margin-bottom: 50px;
    font-size: 2.5em;
    text-align: center;
}

.article {
    margin-bottom: 40px;

    h3 {
        padding: 1rem;
        border-bottom: 1px solid #7ecff8;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px 10px 0 0;
    }
}