.gemini-generator {
    margin-top: 50px;
    margin-bottom: 50px;
    /* background-color: #d8d7da; */
    background-color: var(--bg-dark-gray);
    border-radius: 10px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
  
.gemini-generator h2 {
    font-size: 1.2rem;
    color: var(--color-blue-light);
    margin-bottom: 30px;
}

.description {
  color: var(--color-white);
}

.presentation-intro {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.features-list li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.features-list .icon {
  font-size: 1.5rem;
  color: var(--color-blue-light);
  margin-right: 10px;
}

.features-list.form-row {
  margin: 30px 0;
}

.features-list .feature  {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  border: 1px solid var(--bg-dark-gray);
  padding: 24px;
  align-items: center;
  gap: 16px;
}

.features-list .feature .icon {
  display: block;
  color: var(--color-blue-light);
  margin-right: 10px;
}
  
.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Icônes dans les sélecteurs */
.gemini-generator .form-row .icon {
  position: relative;
  display: block;
  left: 0;
  top: 8px;
  width: 40px;
  color: var(--color-white);
}

/* Disposition des sélecteurs de langue et type de contenu côte à côte */
.form-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
  
.input, .select {
  padding: 12px;
  border: 1px solid var(--color-turquoise);
  border-radius: 4px;
  font-size: 1rem;
  color: #333333;
}

.select {
  width: 50%;
  border: none;
  padding: 0;
}

.input:focus, .select:focus {
  outline: none;
  border-color: var(--color-blue-light);
  box-shadow: 0 0 4px var(--bg-turquoise);
}
  
  .button {
    background-color: var(--color-blue-light);
    color: var(--color-black);
    font-weight: bold;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button:hover {
    background-color: #f9a825;
  }
  
  .button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .spinner {
    margin-right: 8px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  .error-message {
    display: flex;
    align-items: center;
    background-color: #d32f6e;
    color: #ffffff;
    padding: 12px;
    border-radius: 4px;
  }
  
  .error-message .icon {
    margin-right: 8px;
  }
  
  .content {
    margin-top: 24px;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    color: #333333;
  }

  