/* Menu de Navigation */
.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--bg-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    z-index: 1000;
}

.logo {
    padding-right: 20px;
    flex: 1;
}

.titre {
    flex: 3;
    color: var(--color-white);
    text-decoration: none;
    font-size: 1.4em;
    font-weight: 700;
    /* padding-left: 20px; */
}

.nav-menu {
    display: none;
}

.nav-menu li {
    margin-left: 20px;
}

.nav-menu a {
    text-decoration: none;
    color: var(--color-white);
    font-weight: 400;
    transition: color 0.3s;
}

.nav-menu a:hover {
    color: var(--color-blue-light);;
}

/* En-tête */
.header {
    height: 100vh;
    background: url('../../../public/assets/img/mountains.jpg') no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    color: #fff;
}

.header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.8);
}

.header-content {
    position: relative;
    z-index: 2;
}

.header h1 {
    font-size: 2em;
    margin-top: 10vh;
    margin-bottom: 20px;
}

.header p {
    font-size: 1.2em;
    margin-bottom: 40px;
}

.header .add-on {
    background-color: var(--bg-blue-light);
    height: 90px;
    width: 100%;
    position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.neon-btn {
    text-decoration: none;
    background-color: var(--color-blue-light);
    color: var(--color-black);
    padding: 15px 30px;
    border-radius: 50px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    display: inline-block;
    margin-top: 50px;
}

.neon-btn:hover {
    background-color: var(--color-white);
    color: var(--color-blue-light);
}

.custom-icon {
    margin-top: 50px; /* ou toute autre valeur */
    opacity: 0.4;
    color: var(--color-blue-night);
  }

@keyframes wiggle {
    0%, 100% {
        transform: rotate(-15deg); /* Position de départ et de fin */
    }
    50% {
        transform: rotate(15deg); /* Rotation à 15 degrés */
    }
}

.wiggle {
    animation: wiggle 4s ease-in-out infinite; /* 1s pour la durée, 'infinite' pour que ça boucle */
}

@media screen and (min-width:1024px) {

    .titre {
        font-size: 1.6em;
    }

    .nav-menu {
        flex: 5;
        list-style: none;
        display: flex;
        justify-content: flex-end;
    }

    .header h1 {
        font-size: 3em;
        margin-bottom: 20px;
    }
    
}