.footer {
    background-color: var(--bg-dark-gray);
    color: var(--color-white);
    padding: 20px 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    gap: 20px;
  }
  
  .footer-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    /* border: 1px solid red; */
  }
  
  .footer-column h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: var(--color-blue-light);
  }
  
  ul.footer-menu,
  ul.footer-legal {
    list-style-type: none;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border: 1px solid red; */
  }
  
  .footer-menu a,
  .footer-legal a {
    text-decoration: none;
    color: var(--color-blue-light);
    transition: color 0.3s ease;
  }
  
  .footer-menu a:hover,
  .footer-legal a:hover {
    color: var(--color-white);
    text-decoration: underline;
  }

  .footer-logo {
    width: 100%;
    text-align: right;
    /* border: 1px solid red; */
  }
  
  .footer-logo .logo {
    width: 60px;
  }
  
  .footer-social {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
  
  .footer-social a {
    color: var(--color-white);
    font-size: 1.5em;
    transition: color 0.3s ease;
  }
  
  .footer-social a:hover {
    color: var(--color-blue-light);
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9em;
    border-top: 1px solid var(--bg-dark-gray);
    padding-top: 10px;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .footer-column {
      align-items: center;
      text-align: center;
    }
  }




/* En attendant le démarrage */
.footer-social {
  display: none;
}
  