/* Services */

.services {
    padding-bottom: 100px;
    background-color: #111;
    text-align: center;
}

/* .services h2 {
    margin-bottom: 50px;
    font-size: 2.5em;
} */

.no-line {
    text-decoration: none;
    color: #fff;
}

.service-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.service-card {
    background: var(--bg-dark-gray);
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    width: 25%;
    transition: transform 0.3s;
}

.service-card:hover {
    transform: scale(1.05);
}

.service-card h3 {
    margin-bottom: 15px;
    color: var(--color-blue-light);
}

.service-card img {
    width: 100%;
    height: 12rem;
    object-fit: cover;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

/* Card Content */
.service-card .content {
    padding: 1rem;
}

.service-card p {
    color: white;
}

/* Link Styles */
.service-card a {
    text-decoration: none;
    color: inherit;
    display: block;
}

.service-card a:hover {
    text-decoration: none;
}

/* Hide inactive cards */
.service-card[data-active="false"] {
    display: none;
}

/* Styles pour l'affichage en scroll horizontal */
.service-cards.horizontal-scroll {
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 1rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 20px;
}

.service-cards.horizontal-scroll .service-card {
    flex: 0 0 25%; /* 4 services visibles en desktop */
    scroll-snap-align: start;
}

@media (max-width: 768px) {

    .service-card img {
        width: 100%;
        height: 10rem;
    }

    .service-cards.horizontal-scroll .service-card {
        flex: 0 0 80%; /* 1 service visible en mobile */
    }

    .container.diapo {
        width: 100%;
    }
}

/* BOUTONS SCROLL PAGE D'ACCUEIL */
.services-wrapper {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Espace entre les boutons et les cartes */
    align-items: stretch;
}
  
.scroll-button {
    background-color: rgba(40, 40, 40, 0.1);
    color: #00e5ff;
    border: none;
    width: 2em;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Occupe la même hauteur que le conteneur service-cards */
    height: 100%; 
}
  
.scroll-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
  
.horizontal-scroll::-webkit-scrollbar {
    display: none; /* Cache la barre de défilement */
}