:root {
  /* --color-blue: #00e5ff; */
  --color-blue-night: #274D60;
  --color-blue-light: #7ecff8;
  --color-turquoise: #0C969C;
  --color-petrol: #0A7075;
  --color-dark-petrol: #032F30;
  --color-black: #111;
  --color-white: #FFF;
  --bg-dark-gray: rgba(255, 255, 255, 0.1);
  --bg-blue-night: #274D6066;
  --bg-blue-light: #7ecff866;
  --bg-turquoise: #00e5ff66;
  --bg-petrol: #0A707566;
  --bg-dark-petrol: #032F3066;
  --bg-black: rgba(26, 26, 26, 0.9);
  --font-size-h2: 2.5em;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: var(--color-black);
    color: var(--color-white);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  margin-top: 25vh;
  margin-bottom: 5vh;
  font-size: 2.5em;
}