.contact-section {
    min-height: 100vh;
    background: url('../../../public/assets/img/mountains.jpg') no-repeat center center/cover;
    background-attachment: fixed;
    padding: 0 5% 5rem;
    overflow: hidden;
}

.contact-container {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    padding: 1rem 4rem 4rem;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}
  
.contact-form {
    background-color: var(--bg-black);
    width: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem;
}
  
.contact-form .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: calc(100%);
    /* border: 1px solid red; */
}
  
.contact-form .form-group label {
    font-size: 1.1rem;
    margin-bottom: 5px;
}
  
.contact-form .form-group input,
.contact-form .form-group select,
.contact-form .form-group textarea {
    /* width: 100%; */
    padding: 1rem;
    border: 1px solid var(--bg-dark-gray);
    border-radius: 10px;
    background-color: var(--bg-black);
    color: var(--color-white);
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
    outline: none;
    border-color: var(--color-blue-light);
    box-shadow: 0 0 5px var(--color-turquoise);
}
  
input::placeholder,
textarea::placeholder {
    color: #aaa;
}
  
.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.form-group select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.2em;
    padding-right: 2rem; /* Ajuste pour éviter que le texte chevauche l'icône */
}

.captcha-group {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.captcha-checkbox {
    appearance: none;
    width: 25px;
    height: 25px;
    border: 2px solid var(--color-blue-light);
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
}

.captcha-checkbox:checked {
    background-color: var(--color-blue-light);
}
  
.captcha-checkbox:checked::before {
    content: '✓';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -51%);
    color: var(--color-black);
    font-weight: bold;
    font-size: 1.8em;
}

.submit-btn {
    background-color: var(--color-blue-light);
    border: none;
    cursor: pointer;
    font-size: 1rem;
    width: calc(100%);
    color: var(--color-black);
    display: inline-block;
    padding: 15px 30px;
    margin: 1rem 0;
    border-radius: 50px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
  
  .submit-btn:hover {
    background-color: var(--color-white);
    color: var(--color-blue-light);
  }
  
  /* h2 {
    margin-bottom: 20px;
    font-size: 24px;
  } */
  
#modal-container {
    display: none;
}